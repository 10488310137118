import { SET_STEP, SET_INITIALIZE, SET_INFO_SALE_NOTE } from "../actions/aftersale";
import AfterSalesService from "../../../services/requests/AfterSalesService";

const state = {
    currentStep: 1,
    initialize: '',
    saleNote: ''
}

const actions = {
    [SET_STEP]: ({ commit }, step) => {
        commit(SET_STEP, step);
    },

    [SET_INITIALIZE]: ({ commit }, init) => {
        commit(SET_INITIALIZE, init);
    },

    [SET_INFO_SALE_NOTE]: ({ commit }, params) => {
        return new Promise((resolve, reject) => {
            let afterSalesService = new AfterSalesService();
            if (Object.keys(params).length > 0) {

                let client = null;
                if ('client' in params) {
                    client = params.client;
                }

                afterSalesService.getInfoSaleNote(
                    params.lotId,
                    params.auctionId,
                    params.buyerId,
                    client,
                    params.statusLot
                ).then(response => {
                    if (response.status == 200) {
                        commit(SET_INFO_SALE_NOTE, response.data.data)
                        resolve(response.data.data);
                    }
                }).catch(error => reject(error));
            } else {
                commit(SET_INFO_SALE_NOTE, params)
            }

        })
    }
}

const mutations = {
    [SET_STEP]: (state, step) => state.currentStep = step,

    [SET_INITIALIZE]: (state, init) => state.initialize = init,

    [SET_INFO_SALE_NOTE]: (state, response) => state.saleNote = response,
}

const getters = {
    getCurrentStep: (state) => state.currentStep,
    afterSaleInit: ({initialize}) => initialize,
    saleNote: (state) => state.saleNote,
}

export default {
    state,
    actions,
    mutations,
    getters
}
