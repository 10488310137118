import store from './../store/index';
import UserService from "./requests/UserService";
import ST from './StorageTokens'
import VF from "./VuexFunctions";
import VI from "./VerifyImage";
import { SET_MY_LOT_INFO } from './../store/modules/actions/lot';

const tokenControl = {
  validateToken() {
    // console.log('Validate');
    let tokenVuex = store.state.token;
    let tokenCookie = ST.getToken();

    if (tokenCookie) {
      if (tokenVuex) {
        if (tokenCookie != tokenVuex) {
          // console.log('Token diferente');
          this.getUser(tokenCookie)
        }
      } else {
          // console.log('Token novo');
          this.getUser(tokenCookie)
      }
    }
  },
  getUser(tokenCookie) {
    VF.deleteVuex()

    let userService = new UserService()
    userService
      .getMe()
      .then(res => {
        var objUser = {};
        objUser.id = res.data.data.id;
        objUser.name = res.data.data.name;
        objUser.cpf = res.data.data.cpf;
        objUser.email = res.data.data.email;
        objUser.image = VI.userImage(res.data.data.image);
        objUser.buyerId = res.data.data.buyerId;
        objUser.nickname = res.data.data.nickname;
        objUser.isJuridic = res.data.data.isJuridic;
        store.dispatch("setUserLogged", objUser);
        store.dispatch('setToken', tokenCookie)
        store.dispatch(SET_MY_LOT_INFO, {});
      })
      .catch(err => {
        throw new Error("Ocorreu um erro: " + err);
      })
  }
}

export default tokenControl
