import AfterSalesService from "../../../services/requests/AfterSalesService";
import { CHECK_SALARY_INCOME_VALID, SALARY_OCCUPATION_IS_VALID, STORE_SALARY_OCCUPATION } from "../actions/salaryOccupation";

const state = {
  isActive: false,
  loading: false
};

const actions = {
  [CHECK_SALARY_INCOME_VALID]: ({ commit }) => {
    return new Promise((resolve, reject) => {
      (new AfterSalesService).checkSalaryIncomeActive()
      .then(resp => {
        const isValid = resp.data.data;
        resolve(isValid)
        commit('setIsValid', isValid)
      })
      .catch(err => {
        reject(err);
      });
    })
  },

  [STORE_SALARY_OCCUPATION]: ({commit}, params) => {
    return new Promise((resolve, reject) => {
      commit('setLoading', true);
      (new AfterSalesService).storeSalaryIncomeAndOccupation(params)
        .then(resp => {
          commit('setIsValid', true);
          resolve(resp.data.data);
        })
        .catch(err => {
          reject(err.response.data.error);
        })
        .finally(() => {
          commit('setLoading', false);
        })
    })
  }
}

const mutations = {
  setIsValid: (state, value) => state.isActive = value,
  setLoading: (state, value) => state.loading = value,
}

const getters = {
  [SALARY_OCCUPATION_IS_VALID]: (state) => state.isActive,
  isLoading: (state) => state.loading,
}


export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
}
